import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/youtube';
import styles from './youtubePlayer.module.scss';

const YouTubePlayer = ({ url }) => (
  <div className={styles.video}>
    <div>
      <ReactPlayer className="iframe" url={url} width="100%" height="100%" />
    </div>
  </div>
);

YouTubePlayer.propTypes = {
  url: PropTypes.string,
};

YouTubePlayer.defaultProps = {
  url: 'https://www.youtube.com/embed/DZMcaO1AtGg',
};

export default YouTubePlayer;
